<div class="wrap">
  <div class="wrap-center wrap-main">
    <div class="content">
      <h1 *ngIf="movieName; else notFound">{{ movieName }}</h1>
      <div class="kinobox_player" style="width: 100%;"></div>

      <ng-template #notFound>
        <h1>Фильм не найден</h1>
      </ng-template>
    </div>
  </div>
</div>
